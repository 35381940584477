import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-apifastcall',
  templateUrl: './apifastcall.component.html',
  styles: []
})
export class ApifastcallComponent implements OnInit {

  jsCode = "alert('demo 2');"; //  \n $('body').css('background-color', 'red');
  buttonCode:any;
  domainTypeImg: string = '';
  domainTypeName: string = '';
  domainRaportChange: string = '';

  constructor(
    private sanitizer:DomSanitizer,
    private appService:AppService,
    private dataService:DataService,
  ) {
    this.domainTypeName = this.dataService.domainTypeName;
   }

  title = "API";
  ngOnInit() {
    this.appService.setTitleBodyClass(this.title, "");
    this.buttonCode = this.sanitizer.bypassSecurityTrustUrl("javascript:(function(e,a,g,h,f,c,b,d){if(!(f=e.jQuery)||g&gt;f.fn.jquery||h(f)){c=a.createElement(&quot;script&quot;);c.type=&quot;text/javascript&quot;;c.src=&quot;http://ajax.googleapis.com/ajax/libs/jquery/&quot;+g+&quot;/jquery.min.js&quot;;c.onload=c.onreadystatechange=function(){if(!b&amp;&amp;(!(d=this.readyState)||d==&quot;loaded&quot;||d==&quot;complete&quot;)){h((f=e.jQuery).noConflict(1),b=1);f(c).remove()}};a.documentElement.childNodes[0].appendChild(c)}})(window,document,&quot;1.3.2&quot;,function($,L){"+
    this.jsCode+"});");
  }

}
