import { Component, OnInit, Inject, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CallboxTable } from '../models/callboxTable';
import { CompanyAddEditComponent } from '../fragments/company-add-edit.component';
import { Callbox } from '../models/callbox';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-dialog-company-add-edit',
  templateUrl: './dialog-company-add-edit.component.html',
  styles: []
})
export class DialogCompanyAddEditComponent implements OnInit, AfterViewInit {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('addEditDialog') private addEditDialog: CompanyAddEditComponent;
  pending = false;
  formValid = true;

  showDialogContent: boolean = false;
  slide1: boolean = true;
  slide2: boolean = true;
  activeSection = 0;
  list = [1,2,3];
  isEdit = false;
  callbox: Callbox;

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyAddEditComponent>,
    private dataService: DataService,
    private appService: AppService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.callbox = dialogData['data'];
    this.isEdit = dialogData['edit'];
  }

  ngOnInit() {
  }

  formValidChange(event){
    this.formValid = event;
    // if (!this.formValid) this.scrollTo('blockDataAnch',0);
  }

  ngAfterViewInit(){

    setTimeout( ()=>{
      this.showDialogContent = true;
    }, 500)

  }

  scrollTop = 0;
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    this.scrollTop = event.target.scrollTop;

    if (this.scrollTop >= 0 && this.scrollTop <= 148) this.activeSection = 0;
    else if (this.scrollTop > 148 && this.scrollTop <= 350) this.activeSection = 1;
    else if (this.scrollTop > 350 && this.scrollTop <= 1120) this.activeSection = 2;
    else this.activeSection = 3
  }

  scrollTo(elementId, activeSection = null) {
    try {
      var element = document.getElementById(elementId);
      element.scrollIntoView();
      if(activeSection) this.activeSection = activeSection;
      setTimeout( ()=>{
        this.correctScroll()
      },0 )
    } catch(err) { }
  }

  correctScroll(){
    const el = document.getElementById("dialogContentBox");
    el.scrollTop -= 40;
  }

  // isScrolledIntoView(elem) {
  //   const el = document.getElementById("dialogContentBox");
  //   const docViewTop = el.scrollTop();
  //   const docViewBottom = docViewTop + $(window).height();
  //
  //   const elemTop = $(elem).offset().top;
  //   const elemBottom = elemTop + $(elem).height();
  //
  //   return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  // }

  scrollToBottom(activeSection): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        this.activeSection = activeSection;
    } catch(err) { }
  }

  closeDialog(event = null): void {
    this.showDialogContent = false;
    this.dialogRef.close();
  }

  // savingSuccess(event){}

  deletePending = false;
  deleteRequest(){
    this.deletePending = true;
    this.dataService.deleteCallbox(this.callbox.id).subscribe(
      (data)=>{
        this.deletePending = false;
        this.appService.callboxSavedSubject.next(true);
        this.dialogRef.close();
      },(error)=>{
        this.deletePending = false;
        this.appService.callboxSavedSubject.next(true);
        this.dialogRef.close();
      }
    )
  }

  saveCompany(){
    this.addEditDialog.saveCompany();
  }

}
