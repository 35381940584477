export class Utils {

  public static getDate(dateString){
    let date = new Date(dateString);
    let dayNr = date.getDate();
    let day = ""+dayNr;
    if (dayNr<10) day = "0"+dayNr;

    let mthNr = date.getMonth()+1;
    let mth = ""+mthNr;
    if (mthNr<10) mth = "0"+mthNr;

    let str = day+"."+mth+"."+date.getFullYear();
    return str;
  }

  public static getDayName(dayNumber){
    let days = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'];
    return days[dayNumber];
  }

  public static getMonthStart(date:Date){
    date.setDate(1);
    return date;
  }

  public static getMonthEnd(date:Date){
    date.setMonth( date.getMonth() + 1);
    date.setDate( 0 );
    return date;
  }


  public static setBodyClass(className = ""){
    let body = document.getElementById("pageBody"); // TagName('body')[0];
    if(body){
      // body.classList.remove(); //remove the class
      body.removeAttribute("class");
      if(className!="") body.className = className; //add the class
    }
  }

  public static addRemoveBodyClass(className = "", remove = false){
    // let body = document.getElementById("pageBody");
    if(className!=""){
      if(!remove) document.body.classList.add(className); //add the class
      else document.body.classList.remove(className);
    }
  }

  public static randomFromTo(min,max)
  {
    return Math.floor(Math.random()*(max-min+1)+min);
  }


  public static getFile(fileName, blob){
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }

  public static copyToClipboard(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
