import { Callbox } from "./callbox";
import { CallboxGroup } from "./callboxGroup";
import { Stats } from "./stats";

export class UserSite {
  //TODO
  callbox:Callbox = null
  counter: number = null;
  group:CallboxGroup = null;
  stats:Stats = null;
  checked:Boolean = true;

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('counter')) this.counter=json['counter'];
    if (json.hasOwnProperty('callbox')) this.callbox=new Callbox(json['callbox']);
    if (json.hasOwnProperty('group')) this.group=new CallboxGroup(json['group']);
    if (json.hasOwnProperty('stats')) this.stats=new Stats(json['stats'], json['counter']);

  }
}

