import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Agent} from '../models/agent';
import * as _ from 'lodash';
import {Callbox} from '../models/callbox';
import {DataService} from 'src/app/services/data.service';
import {AppService} from 'src/app/services/app.service';
import {DialogInstallFastcallComponent} from '../popups/dialog-install-fastcall.component';
import {MatDialog} from '@angular/material';
import {isArray} from 'util';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-company-add-edit',
  templateUrl: './company-add-edit.component.html',
  styles: []
})
export class CompanyAddEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() savingPending: EventEmitter<boolean> = new EventEmitter();
  @Output() formValidChange: EventEmitter<boolean> = new EventEmitter();
  @Output() letsScroll: EventEmitter<string> = new EventEmitter();
  // @Output() savingSuccess: EventEmitter<boolean> = new EventEmitter();

  public _patternEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public _patternPhone = /^(?:\(?\+?(\d){2})?(?:[-\.\(\)\s]*(\d)){9}\)?$/;
  // "[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,4}"; // ^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*$
  public mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, '-', /[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
  public hourmask = {mask: this.mask, guide: true};
  formInvalid = false;
  domainTypeName: string = '';

  _errorSubscription: Subscription;

  @ViewChild('form') form: NgForm;
  callbox: Callbox;
  @Input() isEdit = true;
  _tempErrors = {
    'web2call_clip': ['Wpisz prawidłowy numer telefonu. Tylko cyfry.'],
    'agents': [
      {
        'msisdn': ['a1 Numer telefonu - wpisz tylko cyfry.'],
        'email': ['a1 Podaj poprawny adres e-mail.']
      },
      {
        'msisdn': ['a2 Numer telefonu - wpisz tylko cyfry.'],
        'email': ['a2 Podaj poprawny adres e-mail.']
      }
    ],
    'managers': [
      {
        'msisdn': ['m1 Numer telefonu - wpisz tylko cyfry.'],
        'name': ['m1 To pole nie może być puste.'],
        'email': ['m1 Podaj poprawny adres e-mail.']
      },
      {
        'msisdn': ['m2 AAA.'],
        'name': ['m2 XXX'],
        'email': ['m2 CCC']
      },
    ],
    'name': ['To pole nie może być puste.']
  };

  @Input() set data(data: Callbox) {
    this.callbox = _.cloneDeep(data);
    delete this.callbox['json'];
  }

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog) {

      this.domainTypeName = this.dataService.domainTypeName;
  }

  ngOnInit() {
    this._errorSubscription = this.appService.errorSubject.subscribe((error) => {
      this.parsingErrors(error);
    });
  }

  ngOnDestroy() {
    this._errorSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.clearErrors();
  }

  addAgents(nr = 3) {
    for (let i = 0; i < nr; i++) {
      this.callbox.agents.push(new Agent(null));
    }
  }

  removeAgent(agent: Agent) {
    _.pull(this.callbox.agents, agent);
  }

  addManagers(nr = 3) {
    for (let i = 0; i < nr; i++)
      this.callbox.managers.push(new Agent(null));
  }

  removeManager(manager: Agent) {
    _.pull(this.callbox.managers, manager);
  }

  saveCompany() {
    this.callbox.web2call_clip = this.cleanPhoneNumnber(this.callbox.web2call_clip);
    this.callbox.agents.forEach( agent=>{
      agent.msisdn = this.cleanPhoneNumnber(String(agent.msisdn));
    })
    this.callbox.managers.forEach( manager=>{
      manager.msisdn = this.cleanPhoneNumnber(String(manager.msisdn));
    });

    // let json = JSON.stringify(this.callbox);
    // console.log("phone json ", json);

    if (this.form.valid) {
      this.savingPending.emit(true);
      this.formValidChange.emit(true);
      this.clearErrors();

      if (this.callbox.id > 0) {
        this.dataService.editCallbox(this.callbox).subscribe((data) => {
          this.onSavingSuccess(data);
        }, (error) => {
          this.onSavingFalse(error.toString());
        });
      } else {
        this.dataService.saveNewCallbox(this.callbox).subscribe((data) => {
          this.onSavingSuccess(data);
        }, (error) => {
          this.onSavingFalse(error.toString());
        });
      }
    } else {
      this.formValidChange.emit(false);
      this.formInvalid = true;
      this.gotoFirstError();
    }
  }

  cleanPhoneNumnber(number:string){
    if (number) number = number.replace(/\D/g,'');
    return number;
  }

  clearErrors() {
    const elements = document.getElementsByClassName('ng-err');
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].textContent = '';
      }
    }
    const fields = document.getElementsByClassName('ng-invalid');
    if (fields && fields.length > 0) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].getAttribute('id')) {
          fields[i].classList.remove('ng-invalid');
        }
      }
    }
  }

  onSavingSuccess(data) {
    this.savingPending.emit(false);
    this.closeDialog.emit();
    this.appService.callboxSavedSubject.next(true);
  }

  onSavingFalse(error = null) {
    // this.parsingErrors(error);
    this.savingPending.emit(false);
  }

  errors = [];

  parsingErrors(errorsArray) {
    this.errors = [];
    this.parseErrors(errorsArray);
    setTimeout(() => {
      this.showErrors();
    }, 0);
  }

  parseErrors(errorsArray, keyName = '') {
    let keys = _.keys(errorsArray);
    keys.forEach(key => {
      if (_.isArray(errorsArray[key])) {
        if (key == 'agents' || key == 'managers') {
          let value = '';
          for (let i = 0; i <= Array(errorsArray[key]).length; i++) {
            this.parseErrors(errorsArray[key][i], key + '_' + i + '_');
          }
        } else {
          let value = '';
          Array(errorsArray[key]).forEach(val => {
            value = value + val + '\n';
          });
          this.errors[keyName + key] = value;
        }
      } else {
        this.errors[keyName + key] = errorsArray[key];
      }
    });
  }

  showErrors() {
    let keys = _.keys(this.errors);
    if (keys) keys.forEach(key => {
      let el = document.getElementById(key);
      if (el) el.classList.add('ng-invalid');
      setTimeout(() => {
        let el = document.getElementById(key + '_error');
        if (el) el.textContent = this.errors[key];
        // else {
        //   console.log('key not found', key+"_error")
        // }
      }, 300);
    });
    this.gotoFirstError();
  }

  gotoFirstError() {
    const elements = document.getElementsByClassName('ng-invalid');
    let id = null;
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        id = elements[i].getAttribute('id');
        if (id) break;
      }
      this.letsScroll.emit(id);
    }
  }

  showInstallationPopup(item: Callbox = null) {
    const dialogRef = this.dialog.open(DialogInstallFastcallComponent, {
      width: '650px',
      minWidth: '650px',
      // disableClose: true,
      panelClass: [], // 'wideDialog', 'formSidesDialog'
      data: {data: item}
    });
  }

  refresh() {
    this.changeDetector.detectChanges();
  }


  onSubmit() {
  }

}

/*
* Po pobraniu bledow od pawla tworzymy obiekt error
* który jest monitorowany przez kontrolki - jesli dany element znajduje sie w tym obiekcie z przypisanym bledem to dodajemy klase
* invalid i komunikat bledu
* nastepnie poszukujemy pierwszej ktora ma nadana klase ng-invalid, pobieramy jej pozycje relatywna
* i tam scrollujemy
*
* */


/*
  {
    "web2call_clip": ["Wpisz prawidłowy numer telefonu. Tylko cyfry."],
    "agents": [{
        "msisdn": ["Numer telefonu - wpisz tylko cyfry."],
        "email": ["Podaj poprawny adres e-mail."]
      }
    ],
    "managers": [{
        "msisdn": ["Numer telefonu - wpisz tylko cyfry."],
        "name": ["To pole nie może być puste."],
        "email": ["Podaj poprawny adres e-mail."]
      }
    ],
    "name": ["To pole nie może być puste."]
  }

 */
