import { UserSite } from "./usersite";

export class UserSites {
  //TODO
  report_counter: number = null
  sites: UserSite[] = [];
  response_time_avg = "";

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('report_counter')) this.report_counter=json['report_counter'];
    if (json.hasOwnProperty('response_time_avg')) this.response_time_avg=json['response_time_avg'];
    if (json.hasOwnProperty('callbox_table')){
      let callbox_table : Array<any> =  json['callbox_table'];
      callbox_table.forEach(element => {
        this.sites.push ( new UserSite(element) )
      });
    }

  }
}
