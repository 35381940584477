import { Call } from "./call";
import { List } from "./list";

export class Calls extends List { //
  list: Call[] = [];

  constructor(json) {
    super(json)
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('results')){
      let results : Array<any> =  json['results'];
      results.forEach(element => {
        this.list.push ( new Call(element) )
      });
    }
  }


}
