export class Stats{
  stats_call_success = 0;
  stats_call_failed = 0;
  stats_call_failed_percent = 0;
  stats_call_success_percent = 0;
  stats_call_not_now = 0;
  stats_call_not_now_percent = 0;
  stats_call_rest = 0;
  stats_call_rest_percent = 0;
  total = 0;

  constructor(json, total = 0){
    if (total) this.total = total;
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('call_success')) this.stats_call_success=json['call_success'];
    if (json.hasOwnProperty('call_failed')) this.stats_call_failed=json['call_failed'];
    // if (json.hasOwnProperty('call_success_percent')) this.stats_call_success_percent=json['call_success_percent'];
    // if (json.hasOwnProperty('call_failed_percent')) this.stats_call_failed_percent=json['call_failed_percent'];

    this.stats_call_success_percent = this.getPercent(Number(this.stats_call_success));
    this.stats_call_failed_percent = this.getPercent(Number(this.stats_call_failed)); //Number(this.stats_call_success_percent.toFixed(0)); //100 - this.stats_call_failed_percent;

    this.stats_call_rest = this.total - this.stats_call_success - this.stats_call_failed;
    this.stats_call_rest_percent = 100 - this.stats_call_success_percent - this.stats_call_failed_percent;

    if (json.hasOwnProperty('call_not_now')) this.stats_call_not_now=json['call_not_now'];
    if (json.hasOwnProperty('call_not_now_percent')) this.stats_call_not_now_percent=json['call_not_now_percent'].toFixed(0);
  }

  getPercent(value:number): number{
    let percent = 0;
    if (this.total > 0) percent = Number( (value / this.total * 100).toFixed(0) );

    return percent
  }

}
