import {Component, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-loader-big',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="loaderBig"><mat-spinner></mat-spinner></div>
  `
})
export class LoaderBigComponent {}
