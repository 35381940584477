import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '../utils/dateutils';
// import {DateUtils} from "../utils/dateutils";

@Pipe({
  name: 'mydate'
})
export class MyDatePipe implements PipeTransform {

  typeShort:string="short";
  typeFull:string="full";

  constructor(){}

  /**
   * timestamp => number
   * string => format iso date_time ex. 2018-01-04T08:47:02.074Z
   *
   {{timestamp or string | mydate}}          -> 04-01-2018
   {{timestamp or string | mydate:"short"}}  -> 04-01-2018
   {{timestamp or string | mydate:"full"}}   -> 04-01-2018 09:47
   * */

  transform(value:any,format:string=this.typeShort) : any {

    let timestamp:number = new Date(value).getTime();

    try {
      switch (format)  {
        case this.typeShort:
          return DateUtils.getDate(timestamp,'.',true);
        case this.typeFull:
          return DateUtils.getFullDate(timestamp);
        default:
          return DateUtils.getDate(timestamp,'.',true);
      }
    } catch(e) {
      return "Wrong date";
    }

  }

}
