import { NgModule } from '@angular/core';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatMenuModule,
  MAT_DATE_LOCALE
} from '@angular/material';

export const MAT_MODULES = [
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTabsModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  // NgxMatSelectSearchModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatMenuModule
];

@NgModule({
  imports: MAT_MODULES,
  exports: MAT_MODULES,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
  ],
})
export class MaterialModule {}
