import {Component, OnDestroy, OnInit} from '@angular/core';
import {Calls} from '../models/calls';
import {AppService} from '../../services/app.service';
import {DataService} from '../../services/data.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-password-change',
  templateUrl: './account-password-change.component.html',
  styles: []
})
export class AccountPasswordChangeComponent implements OnInit, OnDestroy {

  old_password = "";
  new_password = "";
  new_password_confirm = ""
  pending = false;
  notIdentical = false;
  noData = false;
  changeSuccess = false;
  customError = "";
  seconds = 5;

  _subPasswordChange: Subscription;

  constructor(private appService:AppService,
              private dataService:DataService,
              public snackBar: MatSnackBar,
              private router:Router) { }

  ngOnInit() {}

  ngOnDestroy(){
    if (this._subPasswordChange) this._subPasswordChange.unsubscribe();
  }

  submit(){
    if (this.checkValidation()){
      this.submitChangePassword();
    };
  }

  checkValidation(){
    this.clearValidation();
    let isValid = true;
    if ( this.new_password !== this.new_password_confirm ){
      this.notIdentical = true;
      isValid = false;
    }
    if ( this.new_password.trim().length <= 0 || this.new_password.trim().length <= 0 || this.new_password_confirm.trim().length <= 0){
      this.noData = true;
      isValid = false;
    }

    return isValid;
  }

  clearValidation(){
    this.notIdentical = false;
    this.noData = false;
    this.customError = '';
  }

  submitChangePassword(){
    this.pending = true;
    this.clearValidation();
    this._subPasswordChange = this.dataService.changePassword(this.old_password, this.new_password).subscribe( data=>{
      if (data && String(data['status']).toUpperCase() == 'OK'){
        this.changeSuccess = true;
        this.startLogout();
        this.clearValidation();
        this.snackBar.open("Hasło zostało zmienione", "Zamknij", {
          duration: 1000,
        });
      } else if (data && String(data['status']).toUpperCase() == 'ERROR'){
        this.customError = data['error'];
      }
      this.pending = false;
    },error=>{
      this.clearValidation();
      console.error("dataService error",error);
      this.pending = false;
    });
  }

  startLogout(){
    setTimeout( ()=>{
      this.router.navigate(['/login']);
    },5000)

    let myInterval = setInterval( ()=>{
      this.seconds = this.seconds - 1;
      if (this.seconds == 0) clearInterval(myInterval);
    },1000)
  }

}
