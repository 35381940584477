import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Paginator } from '../models/paginator';
import { Utils } from '../../shared/utils/utils';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, flatMap, map, tap} from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { PageChangedEvent } from 'ngx-bootstrap';
import { MatDatepickerInputEvent } from '@angular/material';
import { Notifications } from '../models/notifications';
import { CallboxTable } from '../models/callboxTable';
import {Callbox} from '../models/callbox';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styles: []
})
export class NotificationsComponent implements OnInit, OnDestroy {

  @ViewChild('pickerFrom') datePickerFrom; //  MatDatepicker<Date>;
  @ViewChild('pickerTo') datePickerTo; //  MatDatepicker<Date>;

  title = "Notyfikacje";
  notifications: Notifications;
  paginator = new Paginator();
  dateFrom = new Date(); // Utils.getMonthStart();
  dateTo = new Date();
  status = "";
  search = "";
  pending = false;
  pendingCallboxes = false;
  selectedCallbox = 0;
  selectedSender = 0;
  callboxlist : Callbox[] = [];
  public searchKeyUp = new Subject<string>();
  _subsSearch : Subscription;
  _subsNotification : Subscription;
  _subsCallboxes : Subscription;
  senders = [];

  constructor(private appService: AppService, private dataService: DataService) {
    this.dateFrom.setDate( this.dateFrom.getDate() - 30 );

    this._subsSearch = this.searchKeyUp.pipe(
      map(event => event['target'].value),
        debounceTime(300),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(500)))
    ).subscribe(data=>{
      if (data != this.search){
        this.search = data;
        this.getData(true);
      }
    });

    this.getCallboxes();
    this.getSenders();

  }

  ngOnInit() {
    this.appService.setTitleBodyClass(this.title, "");
    this.getData();
  }

  getData(resetPaginator = false){
    if (resetPaginator) this.paginator.reset();
    this.pending = true;
    let start = Utils.getDate(this.dateFrom);
    let end = Utils.getDate(this.dateTo);
    // let search = this.search.replace(/\s/g, ""); // msisdn, agent
    let sender = String(this.selectedSender)
    let callboxId = String(this.selectedCallbox);
    // let search = '';
    this._subsNotification = this.dataService.getNotification(this.paginator, start, end, sender, this.search, callboxId).subscribe( data=>{
      if (data){
        this.notifications = new Notifications(data);
        this.paginator.totalItems = this.notifications.count;
      }
      this.pending = false;
    },error=>{
      console.error("getData",error);
      this.pending = false;
    });

  }

  getSenders(){
    this._subsCallboxes = this.dataService.getSenders().subscribe( data=>{
      if (data){
        this.senders = data as string[];
        // let list = data['results'];
        // if (list) list.forEach(element => {
        //   this.callboxlist.push ( new Callbox(element) )
        // });
      }
      this.pendingCallboxes = false;
    },error=>{
      this.pendingCallboxes = false;
    });
  }


  getCallboxes(){
    this._subsCallboxes = this.dataService.getCallboxes().subscribe( data=>{
      if (data){
        let list = data['results'];
        if (list) list.forEach(element => {
          this.callboxlist.push ( new Callbox(element) )
        });
      }
      this.pendingCallboxes = false;
    },error=>{
      this.pendingCallboxes = false;
    });
  }

  onSelectDateFrom(type: string, event: MatDatepickerInputEvent<Date>) {
    this.getData(true);
    // this.datePickerTo.open();
  }

  onSelectDateTo(type: string, event: MatDatepickerInputEvent<Date>){
    this.getData(true);
  }

  pageChanged(event:PageChangedEvent){
    this.paginator.currentPage = event.page - 1;
    this.getData();
  }

  ngOnDestroy(){
    if(this._subsSearch) this._subsSearch.unsubscribe();
    if(this._subsNotification) this._subsNotification.unsubscribe();
    if(this._subsCallboxes) this._subsCallboxes.unsubscribe();

  }

}
