export class DateUtils {

  public static getDate(timestamp = null,separator=".",ymd=false) { // using in pipe
    let date = '';
    if(timestamp && timestamp > 0){
      date = this.formatDate(timestamp,separator,ymd);
    }
    return date;
  }

  public static getFullDate(timestamp = null,MYSeparator=".",HHMMSeparator=":",noTime =false) {
    let date = '';
    if(timestamp && timestamp > 0){
      if (!noTime) date = this.formatDateYMHM(timestamp,MYSeparator,HHMMSeparator);
      else date = this.formatDate(timestamp, MYSeparator, true);
    }
    return date;
  }

  public static getYearBeginning(){
    var d = new Date();
    d.setDate(1);
    d.setMonth(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setHours(0);
    d.setMilliseconds(0);
    return d;
  }

  public static formatDateYMHM(date,MYSeparator=".",HHMMSeparator=":") { // using in pipe
    var MMYYYYPart:string = this.formatDate(date, MYSeparator);
    var d = new Date(date);
    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    var HHMMPart = hours+HHMMSeparator+minutes;
    return MMYYYYPart+" "+HHMMPart;
  }

  public static formatDate(date,separator=".",ymd = false) { // using in pipe
    //format dd-mm-yyyy"
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    // return day+"-"+month+"-"+year;
    let dateStr = "";
    if(!ymd) dateStr = [day, month, year].join(separator);
    else dateStr = [year, month, day].join(separator);

    return dateStr;
  }

  public static restParamDate(date):string {
    return this.formatDate(date,"-",true);
  }

  public static formatDateYYYYMMDD_HHMMSS(date:Date, utc:boolean = true):string{
    if (utc){
      return date.getUTCFullYear() + "" +
        this.twoDigitsString((date.getUTCMonth()+1)) +"" +
        this.twoDigitsString(date.getUTCDate()) + "_" +
        this.twoDigitsString(date.getUTCHours()) + "" +
        this.twoDigitsString(date.getUTCMinutes()) + "" +
        this.twoDigitsString(date.getUTCSeconds());
    }else{
      return date.getFullYear() + "" +
        this.twoDigitsString((date.getMonth()+1)) +"" +
        this.twoDigitsString(date.getDate()) + "_" +
        this.twoDigitsString(date.getHours()) + "" +
        this.twoDigitsString(date.getMinutes()) + "" +
        this.twoDigitsString(date.getSeconds());
    }
  }

  public static fileDate(date = new Date(), utc:boolean = true):string{
    if (utc){
      return date.getUTCFullYear() + "-" +
        this.twoDigitsString((date.getUTCMonth()+1)) +"-" +
        this.twoDigitsString(date.getUTCDate());
        // + "_" +
        // this.twoDigitsString(date.getUTCHours()) + "" +
        // this.twoDigitsString(date.getUTCMinutes()) + "" +
        // this.twoDigitsString(date.getUTCSeconds());
    }else{
      return date.getFullYear() + "-" +
        this.twoDigitsString((date.getMonth()+1)) +"-" +
        this.twoDigitsString(date.getDate());
        //  + "_" +
        // this.twoDigitsString(date.getHours()) + "" +
        // this.twoDigitsString(date.getMinutes()) + "" +
        // this.twoDigitsString(date.getSeconds());
    }
  }

  //for formatDateYYYYMMDD_HHMMSS
  private static twoDigitsString(digit):string{
    let digitString = String(digit);
    if (digitString.length === 1) {
      return "0" + digitString;
    }
    return digitString;
  }

  public static addDays(startDate, numberOfDays) {
    var returnDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + numberOfDays,
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds());
    return returnDate;
  }

  public static addMonths(startDate, number) {
    var returnDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + number,
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes(),
      startDate.getSeconds());
    return returnDate;
  }

  public static truncateDateToDay(date){
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  public static parseDate(stringDate:string, isoString:boolean=false):any{
    //format yyyy-mm-dd"
    if (!stringDate) return null;
    try {
      var parts = stringDate.split('-');
      var d:number = Number(parts[2]);
      var m:number = Number(parts[1])-1;
      var y:number = Number(parts[0]);
      return isoString ? new Date(y,m,d).toISOString() : new Date(y,m,d);
    } catch(e){
      console.error("parseDate error",e);
    }
    return null;
  }
}
