import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../services/app.service';
import {MatDialog} from '@angular/material';
import {DialogCompanyAddEditComponent} from '../popups/dialog-company-add-edit.component';
import {DataService} from '../../services/data.service';
import {Callbox} from '../models/callbox';
import {Agent} from '../models/agent';
import {Subject, Subscription} from 'rxjs';
import {CallboxTable} from '../models/callboxTable';
import {DialogInstallFastcallComponent} from '../popups/dialog-install-fastcall.component';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-myfastcall',
  templateUrl: './myfastcall.component.html',
  styles: []
})
export class MyfastcallComponent implements OnInit, OnDestroy {
  // slide1 : boolean = true;
  // slide2 : boolean = false;
  // slide3 : boolean = true;
  list: Callbox[] = [];
  fullList: Callbox[] = [];
  pending = false;
  _subsCallboxes: Subscription;
  _subsDefaults: Subscription;
  _subsDialogClose: Subscription;
  _subsSavingSuccess: Subscription;

  public searchKeyUp = new Subject<string>();
  _searchSubscription: Subscription;

  defaultCallboxJSON: JSON;
  showSearchClear = false;

  domainTypeName: string = '';
  domainTypeMyName: string = '';

  title = ''

  constructor(
    private appService: AppService,
    public dialog: MatDialog,
    public dataService: DataService
  ) {

    this._searchSubscription = this.searchKeyUp.pipe(
      map(event => event['target'].value),
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe(data => {
      if (data) data = data.trim();
      if (data && data.length > 0) {
        this.showSearchClear = true;
        this.filterList(data);
      } else {
        this.showSearchClear = false;
        this.list = _.cloneDeep(this.fullList);
      }

    });
    this.domainTypeName = this.dataService.domainTypeName;
    this.domainTypeMyName = this.dataService.domainTypeMyName;
  }

  ngOnInit() {
    this.title = this.domainTypeName;
    this.appService.setTitleBodyClass(this.title, '');
    this._subsSavingSuccess = this.appService.callboxSavedSubject.subscribe((data) => {
      if (data) this.getData();
    });
    this.getData();
    this.getDefaultCallbox();
  }

  callboxTable: CallboxTable;

  getData() {
    this.pending = true;
    this._subsCallboxes = this.dataService.getCallboxes().subscribe((data) => {
      if (data['results']) {
        this.list = [];
        let list = data['results']; // json['managers'];
        list.forEach(element => {
          this.list.push(new Callbox(element));
        });
        this.fullList = _.cloneDeep(this.list);
        this.pending = false;
      }
    });
  }

  // TODO: filtrowanie w my fasctall
  filterList(search) {
    this.list = this.fullList.filter((callbox) => {
      return this.isStringInCallbox(callbox, search.toUpperCase());
    });
  }

  isStringInCallbox(callbox, search): boolean {
    let found = false;
    if (callbox.name.toUpperCase().includes(search)) {
      return true;
    } else {
      if (callbox.agents && callbox.agents.length > 0) {
        for (let i = 0; i <  callbox.agents.length; i++){
          if ( callbox.agents[i].name.toUpperCase().includes(search) ) { return true }
        }
      } else if (callbox.managers && callbox.managers.length > 0) {
        for (let i = 0; i <  callbox.managers.length; i++){
          if ( callbox.managers[i].name.toUpperCase().includes(search) ) { return true }
        }
      }
    }

    return found;
  }

  defaultsPending = false;

  getDefaultCallbox() {
    this.defaultsPending = true;
    this._subsDefaults = this.dataService.getCallboxDefaults().subscribe((data) => {
      this.defaultCallboxJSON = data as JSON;
      this.defaultsPending = false;
      // console.log("defaults", data);
    });
  }

  callboxVisiblePending = 0;
  _subCallboxVisibleChange: Subscription;

  callboxVisibleChange(callbox: Callbox) {
    this.callboxVisiblePending = callbox.id;
    this._subCallboxVisibleChange = this.dataService.toggleCallboxActivity(callbox.id, callbox.is_visible).subscribe((data) => {
      this.callboxVisiblePending = 0;
    }, (error) => {
      this.callboxVisiblePending = 0;
      callbox.is_visible = !callbox.is_visible;
      console.error(error);
    });
  }

  agentOnlinePending = 0;
  _subAgentVisible: Subscription;

  agentVisibleChange(agent: Agent) {
    this.agentOnlinePending = agent.id;
    this._subAgentVisible = this.dataService.toggleAgentActivity(agent.id, agent.is_online).subscribe((data) => {
      this.agentOnlinePending = 0;
    }, (error) => {
      this.agentOnlinePending = 0;
      agent.is_online = !agent.is_online;
      console.error(error);
    });
  }

  showInstallationPopup(item: Callbox = null) {
    const dialogRef = this.dialog.open(DialogInstallFastcallComponent, {
      width: '650px',
      minWidth: '650px',
      // disableClose: true,
      panelClass: ['relative'], // 'wideDialog', 'formSidesDialog'
      data: {data: item}
    });
  }

  showEditDialog(item: Callbox = null) {
    let edit = true;
    if (!item) {
      item = new Callbox(this.defaultCallboxJSON); // null
      edit = false;
    }
    const dialogRef = this.dialog.open(DialogCompanyAddEditComponent, {
      width: '1260px',
      minWidth: '1260px',
      disableClose: true,
      panelClass: ['wideDialog', 'formSidesDialog'],
      data: {data: item, edit: edit} // {name: "nazwa jakaś", id: 1223}
    });

    this._subsDialogClose = dialogRef.afterClosed().subscribe(result => {
      //  The dialog was closed
    });
  }

  ngOnDestroy() {
    if (this._subsCallboxes) this._subsCallboxes.unsubscribe();
    if (this._subsDialogClose) this._subsDialogClose.unsubscribe();
    if (this._subAgentVisible) this._subAgentVisible.unsubscribe();
    if (this._subCallboxVisibleChange) this._subCallboxVisibleChange.unsubscribe();
    if (this._subsSavingSuccess) this._subsSavingSuccess.unsubscribe();
    if (this._subsDefaults) this._subsDefaults.unsubscribe();
    if (this._searchSubscription) this._searchSubscription.unsubscribe;
  }

}
