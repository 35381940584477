import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../auth.service';

import { first } from 'rxjs/operators';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { User } from 'src/app/main/models/user';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  private _error = new Subject<string>();
  errorMessage: string;

  domainTypeImg: string = '';
  domainTypeName: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private appService:AppService,
    private dataService:DataService,
  ) {
    this.domainTypeImg = this.dataService.domainTypeImg;
    this.domainTypeName = this.dataService.domainTypeName;
  }

  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.appService.setTitleBodyClass("Login page", "login_page");

    this._error.subscribe((message) => this.errorMessage = message);
    this._error.pipe(
      debounceTime(5000)
    ).subscribe(() => this.errorMessage = null);

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.authService.logout();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(this.f.username.value, this.f.password.value)
    .pipe(first())
    .subscribe(data => {
      this.authService.setUser( new User(data.user) );
      this.router.navigate(['dashboard']);
    },
    error => {
      this.loading = false;
      console.error(error);
      this._error.next('Logowanie nieudane. Spróbuj ponownie.');
    });
  }

}
