import { Utils } from "../../shared/utils/utils";

export class Callstatus {
  date: number = null;
  info: string = null;
  name: string = null;
  keypressedDelaySeconds: string = null;
  dayName: string = "";

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('date')) this.date=json['date'];
    if (json.hasOwnProperty('info')) this.info=json['info'];
    if (json.hasOwnProperty('name')) this.name=json['name'];
    if (json.hasOwnProperty('keypressed_delay_seconds')) this.keypressedDelaySeconds=json['keypressed_delay_seconds'];
    if(this.date) this.dayName = Utils.getDayName( new Date(this.date).getDay() );
  }
}
