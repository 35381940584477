import { List } from "./list";
import { Notification } from "./notification";

export class Notifications extends List  {
  list: Notification[] = [];

  constructor(json) {
    super(json);
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('results')){
      let results : Array<any> =  json['results'];
      results.forEach(element => {
        this.list.push ( new Notification(element) )
      });
    }
  }


}
