export class List {
  count: number = null;
  next: string = null;
  previous: string = null;

  constructor(json){
    if (json) this.setData(json);
  }


  setData(json){
    if (json.hasOwnProperty('count')) this.count=json['count'];
    if (json.hasOwnProperty('next')) this.next=json['next'];
    if (json.hasOwnProperty('previous')) this.previous=json['previous'];
  }
}
