import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { AppService } from '../../services/app.service';
import { MatCalendar } from '@angular/material';
import { Utils } from '../../shared/utils/utils';
import { DataService } from '../../services/data.service';
import { UserSites } from '../models/usersites';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ChartjsModule, ChartjsComponent } from '@ctrl/ngx-chartjs';
import { ReportChartComponent } from './report-chart.component';
import {of, Subject, Subscription} from 'rxjs';
import { DateUtils } from 'src/app/shared/utils/dateutils';
import { Stats } from '../models/stats';
import * as moment from 'moment';
import {debounceTime, delay, distinctUntilChanged, flatMap, map} from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  // styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('matCalendarFrom') private matCalendarFrom: MatCalendar<any>;
  @ViewChild('matCalendarTo') private matCalendarTo: MatCalendar<any>;
  @ViewChild('chart') private chart: ReportChartComponent;
  @ViewChild('outsideClick') private outsideClick: ElementRef;

  public mask = [/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /[2]/, /[0-1]/, /[0-9]/, /[0-9]/];
  public datemask = {mask: this.mask, guide: false};

  dt: Date = new Date();
  minDate: Date = void 0

  userSites:UserSites = null;
  dateStart : Date = new Date();
  dateEnd : Date = new Date();
  currentPeriod = '30';

  selectedDateFrom:any;
  selectedDateTo:any;
  dateFromPicker: any;
  dateToPicker: any;

  allChecked: boolean = true;
  domainRaportChange: string = '';


  constructor( private appService:AppService, private dataService: DataService) {
    this.domainRaportChange = this.dataService.domainRaportChange;
   }

  ngOnInit() {
    this.appService.setTitleBodyClass("Dashboard", "");
    this.setStartDate(30);

  }

  ngAfterViewInit(){

  }

  setStartDate(days = 30){
    this.dateEnd = new Date();
    this.dateStart = new Date();
    this.dateStart.setDate( this.dateStart.getDate() - days );

    this.setDateString(String(days));
  }

  setMonthStartEnd(date = null){
    if (date == null){
      date = new Date();
      this.dateEnd = new Date();
    }else{
      this.dateEnd = Utils.getMonthEnd(new Date(date.getTime()));
    }
    this.dateStart = Utils.getMonthStart(new Date(date.getTime()));

    this.setDateString("M");
  }

  selectAll(){
    this.userSites.sites.forEach( (site) => {
      site.checked = this.allChecked;
    })
    if (this.allChecked) this.getDashboardData();
    else this.getDashboardData('-1');
  }

  selectionChange(){
    let selectedArray = [];
    this.userSites.sites.forEach( (site) => {
      if (site.checked) selectedArray.push(site.callbox.id);
      else this.allChecked = false;
    })

    if (this.userSites.sites.length == selectedArray.length)  this.allChecked = true;
    if (selectedArray.length > 0) this.getDashboardData(selectedArray.join(','));
    else this.getDashboardData('-1');
  }

  setPreviousMonth(){
    let date = new Date();
    date.setMonth ( date.getMonth() - 1 );
    this.setMonthStartEnd(date);
    this.setDateString("-1M");
  }

  setDateString(periodName){
    this.currentPeriod = periodName;
    this.selectedDateFrom = Utils.getDate(this.dateStart);
    this.selectedDateTo = Utils.getDate(this.dateEnd);

    // setting MatCalendar vars
    setTimeout( ()=>{
      this.matCalendarFrom.startAt = new Date(this.dateStart.getTime());
      this.matCalendarTo.startAt = new Date(this.dateEnd.getTime());
      this.matCalendarFrom.activeDate = new Date(this.dateStart.getTime());
      this.matCalendarTo.activeDate = new Date(this.dateEnd.getTime());
    },100);

    this.dateFromPicker = this.dateStart;
    this.dateToPicker = this.dateEnd;

    this.getDashboardData();
  }

  pending = false;
  response_time_avg = "";
  private _subGetDashboardData : Subscription;
  getDashboardData(callboxes = null){

    let start = this.selectedDateFrom;
    let end = this.selectedDateTo;
    let _tempUserSites
    this.pending = true;

    this._subGetDashboardData = this.dataService.getDashboardData(start, end, callboxes).subscribe( data=>{

      if (callboxes) _tempUserSites = new UserSites(data);
      else {
        this.userSites = new UserSites(data);
        _tempUserSites = this.userSites;
      }

      this.response_time_avg = _tempUserSites.response_time_avg;
      if(data['stats']) this.stats = this.setStatistics(data['stats'], data['report_counter']);
      this.chartData = data['order_per_hour'];
      this.prepareChartData();
      this.pending = false;
    },error=>{
      console.error("getDashboardData",error);
      this.pending = false;
    });

  }

  popoverPending = false;
  popoverStats;
  getCallboxData(callboxId){
    this.callboxStats = null;
    this.popoverPending = true;
    this._subGetDashboardData = this.dataService.getDashboardData(this.selectedDateFrom, this.selectedDateTo, callboxId)
    .subscribe( data=>{
      this.callboxStats = this.setStatistics(data['stats'], data['report_counter']);
    },error=>{
      console.error("getDashboardData",error);
      this.pending = false;
    });
  }

  dateFromChanged(){
    moment.locale("pl");
    moment().format('L');

    console.log("from");
    let time = moment(this.selectedDateFrom, 'DD.MM.YYYY');
    console.log(time.toDate().getFullYear());
    console.log(this.selectedDateFrom, moment.isDate(time.toDate()) );
  }

  dateToChanged(){
    console.log("to");
  }

  onSelectDateFrom(event){
    console.log(event);
    this.dateFromPicker= event;
    this.selectedDateFrom = Utils.getDate(event);
    this.currentPeriod = "";
    this.getDashboardData();
  }

  onSelectDateTo(event){
    this.dateToPicker=  event;
    this.selectedDateTo = Utils.getDate(event);
    this.currentPeriod = "";
    this.getDashboardData();
  }

  loadMore = false;
  loadMoreToggle(){
    this.loadMore = !this.loadMore;
  }

  chartLabels = [];
  chartValues = {};
  chartData = {};
  prepareChartData(){
    this.chartLabels =  Object.keys(this.chartData);
    this.chartValues = Object.values(this.chartData);
    this.chart.setData(this.chartLabels, this.chartValues);
  }

  _subGetReportCalls:Subscription;
  getReportCalls(){
    let start = this.selectedDateFrom;
    let end = this.selectedDateTo;
    this._subGetReportCalls = this.dataService.getReportCalls(start, end).subscribe((blobContent)=>{
      let filename = DateUtils.fileDate()+"-report.csv";
      Utils.getFile(filename, blobContent);
    });
  }
  _subGetReportNumbers:Subscription;
  getReportNumbers(){
    let start = this.selectedDateFrom;
    let end = this.selectedDateTo;
    this._subGetReportNumbers = this.dataService.getReportNumbers(start, end).subscribe((blobContent)=>{
      let filename = DateUtils.fileDate()+"-msisdn.xlsx";
      Utils.getFile(filename, blobContent);
    });
  }


  ngOnDestroy(){
    if(this._subGetDashboardData) this._subGetDashboardData.unsubscribe;
    if(this._subGetReportCalls) this._subGetReportCalls.unsubscribe;
    if(this._subGetDashboardData) this._subGetDashboardData.unsubscribe;
  }


  scrollTop = 0;
  // @HostListener('scroll', ['$event'])
  // onScroll(event) {
  //   this.outsideClick.nativeElement.click();// as HTMLElement;
  // }

  stats: Stats;
  callboxStats: Stats;
  setStatistics(data, total){
    return new Stats(data, total);
  }

}



