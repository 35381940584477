import {Component, ViewChild, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { PATHS } from '../../shared/paths';
import { User } from '../models/user';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent implements OnInit, OnDestroy {

  user:User;
  private _subUser:Subscription;
  domainTypeName: string = '';
  domainTypeMyName: string = '';
  domainRaportChange: string = '';
  domainUrl = ''

  constructor(
    private authService:AuthService,
    private dataService:DataService,
    ) {

    this.domainTypeName = this.dataService.domainTypeName;
    this.domainTypeMyName = this.dataService.domainTypeMyName;
    this.domainRaportChange = this.dataService.domainRaportChange;
    this.domainUrl = this.dataService.SITE_TYPE === 'oddzwonimy' ? this.paths.mojeOddzwonimy : this.paths.mojFastcall;
  }

  get paths() { return PATHS; }

  ngOnInit(){
    // wywalone changedetection on push
    this.user = new User(null);
    this.authService.userSubject.subscribe( (user)=>{
      if(user) this.user = user;
    })
  }

  ngOnDestroy(){
    if (this._subUser) this._subUser.unsubscribe()
  }

  accountMenuVisible = false;
  showAccountMenu(){
    this.accountMenuVisible = !this.accountMenuVisible;
  }

  closeAccountMenu(){
    if (this.accountMenuVisible) this.accountMenuVisible = false;
  }

  logout() {}

  optionClicked(){ }

}
