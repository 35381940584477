import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JWT_DATA } from './auth.service';

const AUTH_PREFIX = 'JWT';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const jwt_data = JSON.parse(localStorage.getItem(JWT_DATA));
        if (jwt_data && jwt_data.token) {
            request = request.clone({
                setParams: {
                  u: jwt_data.user ? jwt_data.user.id : 0
                },
                setHeaders: {
                    Authorization: `${AUTH_PREFIX} ${jwt_data.token}`
                }
            });
        }

        return next.handle(request);
    }
}
