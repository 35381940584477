import {NgModule} from "@angular/core";
import { PhonePipe } from "./phone.pipe";
import { MyDatePipe } from "./date.pipe";

export const PIPES = [
  PhonePipe,
  MyDatePipe
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {}
