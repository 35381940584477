import {environment} from "../../../environments/environment";
export class Properties {

  /** Default app language **/
  static VERSION = "0.12.19a";

  /** Default app language **/
  static DEFAULT_LANG = "PL";

  /** Available languages that are in assets/locale (temporary loading from const utils/strings-en.ts) */
  static LANGUAGES = {"PL":"PL"};

  /** maximum alerts history in memory */
  static MAX_GLOBAL_ALERTS_HISTORY = 100;

  /** time in ms to hide alert */
  static DEFAULT_ALERT_SHOW_TIME = 3000;

  /** pagination options */
  static DEFAULT_PAGE_SIZE = 20;
  static DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

  /** material modal width */
  static MODAL_WIDTH_SM = '300px';
  static MODAL_WIDTH_MD = '450px';
  static MODAL_WIDTH_BG = '600px';
  static MODAL_WIDTH_LG = '800px';


  // public static get REST_ROOT(): string {
  //   return environment.restRoot;
  // }
  // public static get ENVIRONMENT(): string {
  //   return environment.environment;
  // }

}
