import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';

import { Routes, RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';

import { APP_INITIALIZER } from '@angular/core';
import { AppConfig }       from './app.config';

import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { ErrorInterceptor } from './auth/error.interceptor';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { ErrorHandler } from '@angular/core';
import { AuthErrorHandler } from './auth/auth-error-handler';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { DashboardComponent } from './main/dashboard/dashboard.component';
import { AppService } from './services/app.service';
import { MainMenuComponent } from './main/menu/main-menu.component';
import { MyfastcallComponent } from './main/sites/myfastcall.component';
import { ConnectionsComponent } from './main/sites/connections.component';
import { SmsComponent } from './main/sites/sms.component';
import { ApifastcallComponent } from './main/sites/apifastcall.component';
import { MaterialModule } from './material';
import { CompanyAddEditComponent } from './main/fragments/company-add-edit.component';
import { DialogCompanyAddEditComponent } from './main/popups/dialog-company-add-edit.component';
import { LoaderBigComponent } from './shared/components/loader-big.component';
import { LoaderComponent } from './shared/components/loader.component';
import { DataService } from './services/data.service';
import { NotificationsComponent } from './main/sites/notifications.component';

import { BsDropdownModule,
  PopoverModule, PaginationModule, AlertModule  } from 'ngx-bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DemoBootstrapComponent } from './main/demo/demo-bootstrap.component';

import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { PipesModule } from './shared/pipes';

import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { ReportChartComponent } from './main/dashboard/report-chart.component';
import { TestFieldComponent } from './main/demo/test-field.component';

import { TextMaskModule } from 'angular2-text-mask';
import { DialogInstallFastcallComponent } from './main/popups/dialog-install-fastcall.component';
import { PopupCloseComponent } from './main/fragments/popup-close.component';
import { AccountPasswordChangeComponent } from './main/sites/account-password-change.component';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
// import { PopupDefaultComponent } from './main/popups/popup-default.component';

registerLocaleData(localePl, 'pl');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MainMenuComponent,
    MyfastcallComponent,
    ConnectionsComponent,
    LoaderBigComponent,
    LoaderComponent,
    SmsComponent,
    ApifastcallComponent,
    CompanyAddEditComponent,
    DialogCompanyAddEditComponent,
    NotificationsComponent,
    DemoBootstrapComponent,
    ReportChartComponent,
    TestFieldComponent,
    DialogInstallFastcallComponent,
    PopupCloseComponent,
    AccountPasswordChangeComponent,
    ClickOutsideDirective,
    // PopupDefaultComponent
  ],
  imports: [
    TextMaskModule,
    ChartjsModule,
    BrowserModule,
    BrowserAnimationsModule,
    PipesModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    )
  ],
  entryComponents: [
    DialogCompanyAddEditComponent,
    DialogInstallFastcallComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pl' },
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfig) => () => config.load(), deps: [AppConfig], multi: true },
    AuthService,
    AppService,
    DataService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
