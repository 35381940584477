import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartjsComponent } from '@ctrl/ngx-chartjs';

@Component({
  selector: 'app-report-chart',
  templateUrl: './report-chart.component.html',
  styles: []
})
export class ReportChartComponent implements OnInit {

  @ViewChild('chartjs') public chartjs: ChartjsComponent;

  @Input() chartData = {};
  constructor() { }

  ngOnInit() {}

  setData(labels, data){
    var gradientStroke = null;
    if(this.chartjs){
      var ctx = this.chartjs.ref.nativeElement.getContext("2d");
      gradientStroke = ctx.createLinearGradient(0, 500, 0, 0);
      gradientStroke.addColorStop(0, "rgba(227, 72, 64, 0)");
      gradientStroke.addColorStop(1, "rgba(227, 72, 64, 1)");
    }

    const mappedLabels = labels.map(label => {
      return label.padStart(2, '0') + ':00';
    });

    this.chartData = {
      labels: mappedLabels,
      height:'200px',
      datasets:[
        {
          label: "Statystyki godzinowe połączeń",
          legend:	{display: false, position: 'top'},
          data,
          fill: false,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: 'rgba(227, 72, 64, 1)',
          borderColor:  '#E34840',
          borderWidth: 0,
        },
      ],
    //   options: {
    //     scales: {
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero:true
    //             }
    //         }]
    //     }
    //  }
    }

  }

}

/*
  // [
    // 'rgba(255, 159, 64, 0.2)',
    // 'rgba(255, 205, 86, 0.2)',
    // 'rgba(75, 192, 192, 0.2)',
    // 'rgba(54, 162, 235, 0.2)',
    // 'rgba(153, 102, 255, 0.2)',
    // 'rgba(201, 203, 207, 0.2)',
  // ],
*/
