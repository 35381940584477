import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  cellPhones = [50, 51, 53, 57, 60, 66, 69, 72, 73, 78, 79, 88];

  // transform(value: ParsedNumber, args?: string): any {
  //   if (!value) {
  //     return value;
  //   }
  //   return format(value, 'International');
  // }

  transform(val, args) {
    // val = val.charAt(0) != 0 ? '0' + val : '' + val;

    if (val) {
      if (val.length == 11) {
        if (this.ifCellphone(Number(val[2] + val[3]))) {
          return val[0] + val[1] + ' ' + val[2] + val[3] + val[4] + ' ' + val[5] + val[6] + val[7] + ' ' + val[8] + val[9] + val[10];
        } else {
          return val[0] + val[1] + ' ' + val[2] + val[3] + ' ' + val[4] + val[5] + val[6] + ' ' + val[7] + val[8] + ' ' + val[9] + val[10];
        }
      } else {
        if (this.ifCellphone(Number(val[0] + val[1]))) {
          return this.defaultCellPhoneFormat(val);
        } else {
          if (val.length == 9) {
            return val[0] + val[1] + ' ' + val[2] + val[3] + val[4] + ' ' + val[5] + val[6] + ' ' + val[7] + val[8];
          } else {
            return this.defaultCellPhoneFormat(val);
          }
        }
      }
    } else return val;

  }

  defaultCellPhoneFormat(val) {
    let newStr = '';
    let i = 0;
    for (i = 0; i < (Math.floor(val.length / 3) - 1); i++) {
      newStr = newStr + val.substr(i * 3, 3) + ' ';
    }
    return newStr + val.substr(i * 3);
  }

  ifCellphone(number) {
    return this.cellPhones.includes(number);
  }

}
