import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { MatDatepickerInputEvent } from '@angular/material';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styles: []
})
export class SmsComponent implements OnInit {

  @ViewChild('pickerFrom') datePickerFrom; //  MatDatepicker<Date>;
  @ViewChild('pickerTo') datePickerTo; //  MatDatepicker<Date>;

  list = [1,2,3,4,5,6,7,8,9]

  constructor( private appService:AppService) { }

  title = "SMS";
  ngOnInit() {
    this.appService.setTitleBodyClass(this.title, "");
  }

  onSelectDateFrom(type: string, event: MatDatepickerInputEvent<Date>) {
    this.datePickerTo.open();

  }

}
