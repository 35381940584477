export class Paginator {
  totalItems = 0;
  currentPage = 0;
  maxSize = 10;
  itemsPerPage = 20;

  reset(){
    this.currentPage = 0;
  }
}
