export class CallboxGroup {
  id: number = null
  name: string = null;

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('name')) this.name=json['name'];
  }
}
