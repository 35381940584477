import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-field',
  templateUrl: './test-field.component.html',
  styles: []
})
export class TestFieldComponent implements OnInit {

  constructor() { }

  ngOnInit() {



  }

}
