import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { User } from '../main/models/user';
import { BehaviorSubject } from 'rxjs';

export const JWT_DATA: string = 'fastcall_jwt_token';

@Injectable()
export class AuthService {

  private AUTH_URL: string;
  private isLogged: boolean = false;

  user:User;
  userSubject:BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private config: AppConfig) {
    this.AUTH_URL = this.config.getConfig('AUTH_URL');
  }

  setUser(user:User){
    this.user = user;
    this.userSubject.next(this.user);
  }
  getUser():User{
    return this.user;
  }

  login(username, password) {
    return this.http.post<any>(
      `${this.AUTH_URL}`, {'username': username, 'password': password}
    )
    .pipe(map(data => {
      this.isLogged = true;
      // login successful if there's a jwt token in the response
      if (data && data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(JWT_DATA, JSON.stringify(data));
      }

      return data;
    }));
  }

  logout() {
    localStorage.removeItem(JWT_DATA);
    this.isLogged = false;
  }

  isUserLogged(){
    return this.isLogged;
  }

}
