import { Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { PATHS } from './shared/paths';
import { ConnectionsComponent } from './main/sites/connections.component';
import { MyfastcallComponent } from './main/sites/myfastcall.component';
import { SmsComponent } from './main/sites/sms.component';
import { ApifastcallComponent } from './main/sites/apifastcall.component';
import { NotificationsComponent } from './main/sites/notifications.component';
import {AccountPasswordChangeComponent} from './main/sites/account-password-change.component';

export const appRoutes: Routes = [
  { path: PATHS.login, component: LoginComponent },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: PATHS.passwordChange, component: AccountPasswordChangeComponent, canActivate: [AuthGuard] },
  { path: PATHS.dashboard, component: DashboardComponent, canActivate: [AuthGuard]}, // TODO remember change mojeOddzwonimy to mojeFastcall
  { path: PATHS.mojFastcall, component: MyfastcallComponent, canActivate: [AuthGuard] },
  { path: PATHS.connections, component: ConnectionsComponent, canActivate: [AuthGuard] },
  { path: PATHS.sms, component: SmsComponent, canActivate: [AuthGuard] },
  { path: PATHS.notifications, component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: PATHS.api, component: ApifastcallComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login'}
];
