import {Injectable, EventEmitter} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {Subject, BehaviorSubject} from 'rxjs';
import {Properties} from '../shared/consts/properties';

@Injectable()
export class AppService {
  prefix: string;
  suffix: string;
  isLogged: boolean = false;

  loggedOutEvent: EventEmitter<boolean> = new EventEmitter();
  previousUrl: string = '';
  setTitleSubject: BehaviorSubject<string> = new BehaviorSubject('');
  setBodyClassSubject: BehaviorSubject<string> = new BehaviorSubject('');
  callboxSavedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorSubject: Subject<any> = new Subject();

  constructor(
    private router: Router
  ) {
  }

  setTitleBodyClass(title: string, bodyClass = '') {  // bodyClass:string|null=null)
    this.setTitle(title);
    // bodyClass = (bodyClass != null)?bodyClass:('siteTitle_'+title);
    this.setBodyClass(bodyClass);
  }

  setTitle(title: string) {
    this.setTitleSubject.next(title);
  }

  setBodyClass(bodyClass: string | null = null) {
    this.setBodyClassSubject.next(bodyClass);
  }

  setError(error){
    this.errorSubject.next(error);
  }

  /** GLOBAL ALERTS */
  alertSuccess(title: string, content: string, timeout: number = Properties.DEFAULT_ALERT_SHOW_TIME) {
  }

  alertInfo(title: string, content: string, timeout: number = Properties.DEFAULT_ALERT_SHOW_TIME) {
  }

  alertWarning(title: string, content: string, timeout: number = Properties.DEFAULT_ALERT_SHOW_TIME) {
  }

  alertDanger(title: string, content: string, timeout: number = Properties.DEFAULT_ALERT_SHOW_TIME) {
  }
}
