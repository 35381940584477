import { Callstatus } from "./callstatus";
import { Agent } from "./agent";
import { Utils } from "../../shared/utils/utils";
import { Callbox } from "./callbox";

export class Call {
  id: number = null
  agent: Agent = null
  billsec: number = null;
  billsecDisplay: string = null;
  dateCreated: string = null;
  dayName: string = "";
  msisdn: string = null;
  callbox: Callbox = null;
  source: string = null;
  utmSource: string = null;
  statuses: Callstatus[] = [];
  lastStatus: string = ""

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('agent')) this.agent=new Agent(json['agent']);
    if (json.hasOwnProperty('billsec')) this.billsec=json['billsec'];
    if (json.hasOwnProperty('billsec-display')) this.billsecDisplay=json['billsec-display'];
    if (json.hasOwnProperty('date-created')) this.dateCreated=json['date-created'];
    if (json.hasOwnProperty('msisdn')) this.msisdn=json['msisdn'];
    if (json.hasOwnProperty('source')) this.source=json['source'];
    if (json.hasOwnProperty('callbox')) this.callbox=new Callbox(json['callbox']);
    if (json.hasOwnProperty('utm_source')) this.utmSource=json['utm_source'];
    if (json.hasOwnProperty('status-list')){
      let list : Array<any> =  json['status-list'];
      for(let i = 0; i < list.length; i++){
        let callstatus = new Callstatus(list[i]) ;
        this.statuses.push ( callstatus )
        if ( i == (list.length-1) ) this.lastStatus = callstatus.name;
      }

      // list.forEach(element => {
      //   this.statuses.push ( new Callstatus(element) )
      // });
    }
    if(this.dateCreated) this.dayName = Utils.getDayName( new Date(this.dateCreated).getUTCDay() );
  }
}
