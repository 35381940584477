export class User {
  id: number = null;
  email: string = null;
  username: string = null;
  companyName: string = null;

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('email')) this.email=json['email'];
    if (json.hasOwnProperty('username')) this.username=json['username'];
    if (json.hasOwnProperty('company_name')) this.companyName=json['company_name'];
  }
}
