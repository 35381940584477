import { CallboxGroup } from "./callboxGroup";
import { Agent } from "./agent";

export class Callbox {
  id: number = null
  name: string = null;
  is_visible: boolean = false;
  hours_always: boolean = false;
  web2call_clip: string = null;
  hours_1: string = null;
  hours_2: string = null;
  hours_3: string = null;
  hours_4: string = null;
  hours_5: string = null;
  hours_6: string = null;
  hours_0: string = null;
  sms_call_error: string = null;
  sms_call_offline: string = null;
  sms_call_online: string = null;
  embed_code_display: string = null;
  is_displayed = false;

  group: CallboxGroup = null;
  managers: Agent[] = [];
  agents: Agent[] = [];
  json: JSON;

  constructor(json = null) {
    if (json) this.fromJson(json);
    else this.prepareData();
  }

  fromJson(json){
    this.json = json;
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('name')) this.name=json['name'];
    if (json.hasOwnProperty('web2call_clip')) this.web2call_clip=json['web2call_clip'];
    if (json.hasOwnProperty('is_visible')) this.is_visible=json['is_visible'];
    if (json.hasOwnProperty('hours_always')) this.hours_always=json['hours_always'];
    if (json.hasOwnProperty('hours_1')) this.hours_1=json['hours_1'];
    if (json.hasOwnProperty('hours_2')) this.hours_2=json['hours_2'];
    if (json.hasOwnProperty('hours_3')) this.hours_3=json['hours_3'];
    if (json.hasOwnProperty('hours_4')) this.hours_4=json['hours_4'];
    if (json.hasOwnProperty('hours_5')) this.hours_5=json['hours_5'];
    if (json.hasOwnProperty('hours_6')) this.hours_6=json['hours_6'];
    if (json.hasOwnProperty('hours_0')) this.hours_0=json['hours_0'];
    if (json.hasOwnProperty('embed_code_display')) this.embed_code_display=json['embed_code_display'];
    if (json.hasOwnProperty('is_displayed')) this.is_displayed=json['is_displayed'];



    if (json.hasOwnProperty('sms_call_error')) this.sms_call_error=json['sms_call_error'];
    if (json.hasOwnProperty('sms_call_offline')) this.sms_call_offline=json['sms_call_offline'];
    if (json.hasOwnProperty('sms_call_online')) this.sms_call_online=json['sms_call_online'];

    if (json.hasOwnProperty('group')) this.group=new CallboxGroup(json['group']);
    if (json.hasOwnProperty('managers')){
      let managersList : Array<any> =  json['managers'];
      if (managersList){
        managersList.forEach(element => {
          this.managers.push ( new Agent(element) )
        });
      }
    }
    if (json.hasOwnProperty('agents')){
      let agentsList : Array<any> =  json['agents'];
      if (agentsList){
        agentsList.forEach(element => {
          this.agents.push ( new Agent(element) )
        });
      }
    }
  }

  prepareData() {
    this.sms_call_online = 'Dziękujemy za zainteresowanie ofertą {callbox}. Wkrótce się z Tobą skontaktujemy. Zadzwonimy z numeru: {clip}';
    this.sms_call_offline = 'Dziękujemy za zainteresowanie ofertą {callbox}. Obecnie biuro jest nieczynne. Oddzwonimy w godz pracy biura: pn-pt: 9-17. Do usłyszenia!';

    this.hours_1 = '09:00-17:00';
    this.hours_2 = this.hours_1;
    this.hours_3 = this.hours_1;
    this.hours_4 = this.hours_1;
    this.hours_5 = this.hours_1;

    this.sms_call_error = '';
  }


}

