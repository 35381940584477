import { Callstatus } from "./callstatus";
import { Agent } from "./agent";
import { Utils } from "../../shared/utils/utils";
import { Callbox } from "./callbox";

export class Notification {
  id: number = null;
  date: string = null;
  dayName: string = "";
  msisdn: string = null;
  recipient: string = null;
  info: string = null;
  message: string = null;
  sender: string = null;
  warning: boolean = false;
  type: string = "";


  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('date')) this.date=json['date'];
    if (json.hasOwnProperty('msisdn')) this.msisdn=json['msisdn'];
    if (json.hasOwnProperty('recipient')) this.recipient=json['recipient'];
    if (json.hasOwnProperty('message')) this.message=json['message'];
    if (json.hasOwnProperty('info')) this.info=json['info'];
    if (json.hasOwnProperty('sender')) this.sender=json['sender'];
    if (json.hasOwnProperty('warning')) this.warning=json['warning'];
    if (json.hasOwnProperty('type')) this.type=json['type'];
    if(this.date) this.dayName = Utils.getDayName( new Date(this.date).getUTCDay() );
  }
}
