import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { AppService } from '../../services/app.service';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material';
import { DataService } from '../../services/data.service';
import { Calls } from '../models/calls';
import { Paginator } from '../models/paginator';
import { PageChangedEvent } from 'ngx-bootstrap';
import { Utils } from '../../shared/utils/utils';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, flatMap, map, tap} from 'rxjs/operators';
import { Call } from '../models/call';
import { DateUtils } from '../../shared/utils/dateutils';
import { DomSanitizer } from '@angular/platform-browser';
import {CallboxTable} from '../models/callboxTable';
import {Callbox} from '../models/callbox';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styles: []
})
export class ConnectionsComponent implements OnInit, OnDestroy {
  @ViewChild('pickerFrom') datePickerFrom; //  MatDatepicker<Date>;
  @ViewChild('pickerTo') datePickerTo; //  MatDatepicker<Date>;
  @ViewChild('audio') audio: ElementRef; //  MatDatepicker<Date>;

  title = "Połączenia";
  calls:Calls;
  paginator = new Paginator();
  dateFrom = new Date();//Utils.getMonthStart(new Date());
  dateTo = new Date();
  status = "";
  msisdn = "";
  pending = false;
  selectedCallbox = 0;
  callboxlist : Callbox[] = [];
  pendingCallboxes = false;
  public searchKeyUp = new Subject<string>();
  _searchSubscription : Subscription;
  _subsCallboxes : Subscription;

  constructor( private appService:AppService, private dataService:DataService, private sanitized: DomSanitizer) {
    this.dateFrom.setDate( this.dateFrom.getDate() - 30 );

    this._searchSubscription = this.searchKeyUp.pipe(
      map(event => event['target'].value),
        debounceTime(300),
        distinctUntilChanged(),
        flatMap(search => of(search).pipe(delay(500)))
    ).subscribe(data=>{
      if (data != this.msisdn){
        this.msisdn = data;
        this.getData(true);
      }
    });

    this.getCallboxes();
  }

  ngOnInit() {
    this.appService.setTitleBodyClass(this.title, "");
    this.getData();
  }

  getData(resetPaginator = false){
    if (resetPaginator) this.paginator.reset();
    this.pending = true;
    let start = Utils.getDate(this.dateFrom);
    let end = Utils.getDate(this.dateTo);
    let msisdn = this.msisdn.replace(/\s/g, "");
    let status = String(this.status);
    let agent = '';
    let callboxId = String(this.selectedCallbox);
    this.dataService.getCalls(this.paginator, start, end, status, msisdn, agent, callboxId).subscribe( data=>{
      if (data){
        this.calls = new Calls(data);
        this.paginator.totalItems = this.calls.count;
      }
      this.pending = false;
    },error=>{
      console.error("getData",error);
      this.pending = false;
    });
  }


  getCallboxes(){
    this._subsCallboxes = this.dataService.getCallboxes().subscribe( data=>{
      if (data){
        let list = data['results'];
        if (list) list.forEach(element => {
          this.callboxlist.push ( new Callbox(element) )
        });
      }
      this.pendingCallboxes = false;
    },error=>{
      this.pendingCallboxes = false;
    });
  }

  // showSpinner = false;
  // start = () => { this.showSpinner = true }
  // stop = () => { this.showSpinner = false }
  downloadingId = 0;
  source;
  getRecord(call:Call){
    this.downloadingId = call.id;
    this.dataService.getRecords(call.id).subscribe( (blobContent)=>{
      // let pdfBlob = new Blob([blobContent], {type: "audio/mp3"}); // this.mp3_url = window.URL.createObjectURL(this.pdfBlob);
      this.downloadingId = 0;
      let filedate = DateUtils.formatDateYYYYMMDD_HHMMSS(new Date(call.dateCreated), true );
      let filename = "nagranie_"+filedate+"_nr"+call.msisdn + '.mp3';

      this.source = window.URL.createObjectURL(blobContent);   // returns blob:http://localhost:4200/e6a5a51e-ae11-4b77-9b2c-06251b13ca39

      let audioContainer = document.getElementById("audioContainer");
      var audio = document.createElement('audio');
      audio.setAttribute("controlslist","nodownload");
      audio.src = this.source;
      // audioContainer.appendChild(audio);

      if(this.audio && this.audio.nativeElement){
        this.audio.nativeElement.src = this.source;
        this.audio.nativeElement.autoplay = true;
      }
      //

      setTimeout( ()=>{
        // audio.pause();
      },5000)

      // Utils.getFile(filename, blobContent);
    },(error)=>{
      this.downloadingId = 0;
    })
  }

  onSelectDateFrom(type: string, event: MatDatepickerInputEvent<Date>) {
    this.getData(true);
    // this.datePickerTo.open();
  }

  onSelectDateTo(type: string, event: MatDatepickerInputEvent<Date>){
    this.getData(true);
  }

  pageChanged(event:PageChangedEvent){
    this.paginator.currentPage = event.page - 1;
    this.getData();
  }

  ngOnDestroy(){
    if(this._searchSubscription) this._searchSubscription.unsubscribe();
    if(this._subsCallboxes) this._subsCallboxes.unsubscribe();
  }
}
