export const PATHS = {
  home: '/',
  notFound: '404',
  noPermission:'no-permission',
  login: 'login',
  logout: 'logout',
  passwordChange: 'password-change',
  dashboard: 'dashboard',
  reports: 'reports',
  mojFastcall: 'mojfastcall',
  mojeOddzwonimy: 'mojeoddzwonimy',
  connections: 'connections',
  notifications: 'notifications',
  sms: 'sms',
  api: 'api',
};

