import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AppConfig {

    private config: Object = null;

    constructor(private http: Http) {

    }

    public getConfig(key: any) {
        return this.config[key];
    }

    public load() {
        return new Promise((resolve, reject) => {
            this.http.get('env.json')
            .pipe(map(res => res.json()))
            .subscribe( (data) => {
              this.config = data;
              resolve(true);
            }, () => {
              console.error('Configuration file "env.json" could not be read');
              reject();
            });
        });
    }
}
