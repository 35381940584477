import { Component } from '@angular/core'; // ChangeDetectorRef
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';
import { Utils } from './shared/utils/utils';
import { Title } from '@angular/platform-browser';
import { Properties } from './shared/consts/properties';
import { AuthService, JWT_DATA } from './auth/auth.service';
import { User } from './main/models/user';
import * as moment from 'moment';
import {LocaleSpecification} from 'moment';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  setTitleSub:Subscription = new Subscription();
  setClassSub:Subscription = new Subscription();
  isUserLogged:Subscription = new Subscription();
  version:String = "";
  isLoggedIn:boolean = false;
  year = new Date().getFullYear();
  domainTypeImg: string = '';
  domainTypeName: string = '';
  domainTypeMyName: string = '';
  domainRaportChange: string = '';

  constructor(
    private appService:AppService,
    private authService:AuthService,
    private router: Router,
    private titleService:Title,
    private dataService:DataService,
    // private locale: LocaleSpecification
    // private changeDetectorRef:ChangeDetectorRef
  ){
    // moment.defineLocale( 'pl-PL' , this.locale);
    moment.locale("pl");
    moment().format('L');

    this.domainTypeImg = this.dataService.domainTypeImg;
    this.domainTypeName = this.dataService.domainTypeName;
    this.domainTypeMyName = this.dataService.domainTypeMyName;
    this.domainRaportChange = this.dataService.domainRaportChange;

    this.setTitleSub = this.appService.setTitleSubject.subscribe(title => {
      if (title) title = ' - '+title;
        this.titleService.setTitle( this.domainTypeName + 'APP' + title);
      });
    this.setClassSub = this.appService.setBodyClassSubject.subscribe( (bodyClass)=>{
      if (bodyClass != null) { Utils.setBodyClass(bodyClass); }
    });
    this.version = Properties.VERSION;
    this.setHtmlVersion();

    if (localStorage.getItem(JWT_DATA)){
      let jwtData = JSON.parse(localStorage.getItem(JWT_DATA));
      if(jwtData && jwtData['user']){
        this.authService.setUser(new User(jwtData['user']) );
      }
      this.isLoggedIn = true;
    }
    else this.isLoggedIn = false;


  }

  setHtmlVersion(){
    document.getElementsByTagName("html")[0].classList.add("v_"+this.version); // .setAttribute("id", this.version+"_"+this.env);
  }

}


