import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DataService} from '../../services/data.service';
import {AppService} from '../../services/app.service';
import {Inject} from '@angular/core';

export class PopupDefaultComponent  {

  constructor( public dialogRef: MatDialogRef<any>) { }


  closeDialog(event = null): void {
    this.dialogRef.close();
  }

}
