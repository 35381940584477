export class Agent {
  id: number = null;
  name: string = null;
  email: string = null;
  msisdn: string = null;
  is_online: boolean = true;
  initials: string = "";

  constructor(json) {
    if (json) this.fromJson(json);
  }

  fromJson(json){
    if (json.hasOwnProperty('id')) this.id=json['id'];
    if (json.hasOwnProperty('name')) this.name=json['name'];
    if (json.hasOwnProperty('email')) this.email=json['email'];
    if (json.hasOwnProperty('msisdn')) this.msisdn=json['msisdn'];
    if (json.hasOwnProperty('is_online')) this.is_online=json['is_online'];

    // const initials = this.name.match(/\b\w/g) || [];
    // this.initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    if(this.name){

      if(this.name.split(" ").length > 0)
        this.initials = this.name.split(" ").map((n)=>n[0]).join("").substring(0, 2)
      else
        this.initials = this.name.substring(0, 2);

    }

  }
}

export interface AgentPost {
  id?:number;//for rest put, delete query param
  name?:string;
  // requestParams?:RequestParams; //for reload list after delete
}
